<template>
  <div class="wrap" id="toBuy">
    <div class="toBuy_head hidden-mobile-up">
      <h2>解鎖確認</h2>
      <img
        @click="cancel()"
        class="close_btn"
        src="../../assets/占星小鋪/Cancel.svg"
        alt=""
      />
    </div>
    <div class="toBuy_box">
      <div class="close_btn hidden-mobile-up" @click="cancel()">
        <img src="../../assets/占星小鋪/Cancel2.svg" alt="" />
      </div>
      <div class="toBuy_icon">
        <img src="../../assets/占星小鋪/friendStar.svg" alt="" />
      </div>
      <div class="toBuy_title">是否解鎖此星盤好友？</div>
      <div class="toBuy_subtitle">
        <span>！</span>
        解鎖後此星盤資料將無法更改或刪除， 包含姓名、性別、出生資訊...等。
      </div>
      <div class="toBuy_btnGroup mt-10">
        <div class="cancel_btn hidden-mobile-down" @click="cancel()">返回</div>
        <div class="next_btn" @click="next()">前往購買</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    if (!this.$store.state.orderCreater.ProductID) {
      return this.$router.replace("/");
    }
  },
  methods: {
    cancel() {
      this.$router.push("/store/selectTarget/");
    },
    next() {
      this.$router.push("/order/checkInfo/");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.wrap#toBuy {
  width: 100%;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  @include mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 101;
    display: block;
  }
  .toBuy_head {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px 15px 40px;
    background-color: #e7e8e9;
    color: $secondary-color-1;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }
  .toBuy_box {
    margin-top: 72px;
    width: 472px;
    height: 408px;
    background: #f0f2f4;
    box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
      4px 4px 25px rgba(0, 0, 0, 0.3);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    position: relative;
    @include mobile {
      width: 100%;
      height: 100vh;
      box-shadow: none;
      border-radius: 0;
      margin-top: 0;
    }
    .close_btn {
      position: absolute;
      left: 24px;
      top: 24px;
    }
    .toBuy_icon img {
      width: 104px;
    }
    .toBuy_title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: $theme-color-2;
    }
    .toBuy_subtitle {
      margin: 36px auto 0;
      padding-left: 24px;
      width: 300px;
      font-size: 16px;
      line-height: 28px;
      color: #000;
      position: relative;
      span {
        display: flex;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #f54811;
        color: #fff;
        font-size: 12px;
        @include center;
        position: absolute;
        top: 4px;
        left: 0;
      }
    }
    .toBuy_btnGroup {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include mobile {
        justify-content: center;
        position: fixed;
        left: 0;
        bottom: 40px;
      }
      .cancel_btn {
        @include btn(176px, 56px, transparent, #9b9b9b);
        border: 2px solid #d9d9d9;
      }
      .next_btn {
        @include btn-default(176px, 56px);
        @include mobile {
          @include btn-default(250px, 56px);
        }
      }
    }
  }
}
</style>
