var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap", attrs: { id: "toBuy" } }, [
    _c("div", { staticClass: "toBuy_head hidden-mobile-up" }, [
      _c("h2", [_vm._v("解鎖確認")]),
      _c("img", {
        staticClass: "close_btn",
        attrs: { src: require("../../assets/占星小鋪/Cancel.svg"), alt: "" },
        on: {
          click: function($event) {
            return _vm.cancel()
          }
        }
      })
    ]),
    _c("div", { staticClass: "toBuy_box" }, [
      _c(
        "div",
        {
          staticClass: "close_btn hidden-mobile-up",
          on: {
            click: function($event) {
              return _vm.cancel()
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("../../assets/占星小鋪/Cancel2.svg"),
              alt: ""
            }
          })
        ]
      ),
      _vm._m(0),
      _c("div", { staticClass: "toBuy_title" }, [
        _vm._v("是否解鎖此星盤好友？")
      ]),
      _vm._m(1),
      _c("div", { staticClass: "toBuy_btnGroup mt-10" }, [
        _c(
          "div",
          {
            staticClass: "cancel_btn hidden-mobile-down",
            on: {
              click: function($event) {
                return _vm.cancel()
              }
            }
          },
          [_vm._v("返回")]
        ),
        _c(
          "div",
          {
            staticClass: "next_btn",
            on: {
              click: function($event) {
                return _vm.next()
              }
            }
          },
          [_vm._v("前往購買")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "toBuy_icon" }, [
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/friendStar.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "toBuy_subtitle" }, [
      _c("span", [_vm._v("！")]),
      _vm._v(
        "\n      解鎖後此星盤資料將無法更改或刪除， 包含姓名、性別、出生資訊...等。\n    "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }